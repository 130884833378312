<template>
  <div id="app">
    <VuePNotify :options="options"></VuePNotify>
   
    <div v-if="loader" class="is-loader w-100 h-100">
      <div class="content d-flex justify-content-center align-items-center">
        <img
          src="./assets/global_assets/images/logo_dark.png"
          alt="Loading.."
        />
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("auth", ["user"]),
  },
  data() {
    return {
      loader: false,
      options: {
        defaultStyle: "success",
        defaultDelay: 3000,
        width: "400px",
        animateConfig: {
          animate: true,
          in_class: "lightSpeedIn",
          out_class: "lightSpeedOut",
        },
      },
    };
  },
  mounted() {
    this.$on("loader:hide", () => {
      this.loader = false;
    });
  },
};
</script>
<style src="vue-pnotify/dist/vue-pnotify.css"></style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700,900");
html {
  scroll-behavior: smooth;
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
}
.btn-primary{
  background-color: #ffcf59 !important;
}
.ui-pnotify {
  right: calc(50% - 150px) !important;
  .alert,
  .media-chat-item {
    border-radius: 4px !important;
  }
  .alert-warning{
    border-color: transparent !important;
  }
  .ui-pnotify-container {
    padding: 0.6em !important;
  }

  .ui-pnotify-title {
    font-size: 1.4rem !important;
    font-weight: bold;
  }
  .ui-pnotify-text {
    font-size: 1.1rem !important;
  }
}
.is-loader {
  min-height: 100vh;
  min-width: 100vw;
  z-index: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.content {
  background: #fbffff !important;
}
.v-card.v-sheet.theme--light {
  border-radius: 8px;
}

</style>
