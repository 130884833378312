<template>
    <div class="">
        <v-autocomplete
            :items="sites"
            item-text="url"
            item-value="id"
            v-model="$store.state.site_id"
            append-icon="mdi-link"
            label="Select Site"
            dense
            solo
            class="text-left"
            hide-details
            @change="$emit('siteSelected')"
          ></v-autocomplete>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    computed: {
        ...mapState("sites", ["sites"]),
    },
    methods: {
        ...mapActions("sites", ["getSites"])
    },

    mounted() {
        this.getSites()
    }
}
</script>

<style lang="scss" scoped>
.v-text-field__details{
    display: none !important;
}
.v-list {
    text-align: left;
}
</style>