<template>
  <!-- Main sidebar -->
  <div
    class="sidebar sidebar--dark bg-gradient-primary sidebar-main sidebar-fixed sidebar-expand-md"
  >
    <!-- Sidebar mobile toggler -->
    <div class="sidebar-mobile-toggler text-center">
      <a @click="_toggleSidebarMobile" class="sidebar-mobile-main-toggle">
        <i class="icon-arrow-left8"></i>
      </a>
      <h1 class=" font-weight-bolder logo__custom  ">
      {{site_settings.name }}
      </h1>
      <a @click="_toggleSidebarMobile" class="sidebar-mobile-main-toggle">
        <i class="icon-x"></i>
      </a>
    </div>

    <!-- /sidebar mobile toggler -->

    <!-- Sidebar content -->
    <div class="sidebar-content mt-sm-50 pb-0">
     
      <div style="text-align: start!important;" class="navbar-brand d-sm-flex d-none align-items-center justify-content-center ">
      <a href="/" class="d-inline-block">
          <h1 class=" font-weight-bolder logo__custom  ">
          {{site_settings.name }}
          </h1>
      </a>
      </div>
      <div v-if="userType != userTypes.writer" class="d-flex align-items-center justify-content-center mt-sm-0 mt-3 text-center">
          <router-link style="width: 80%!important;" :to="{ name: 'PlaceOrder' }"  class="btn-main px-4 btn--custom" type="button" id="of-onbording">Order now</router-link>
      </div>

      <!-- Main navigation -->
      <div class="shadow">
        <ul class="navbar-nav">
          <!-- Sidebar - Brand -->
          <!-- <a class="sidebar-brand d-flex align-items-center" href="/orders" >
          </a>   -->
          <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs color-faded line-height-xs">Main</div>
            <!-- <i class="icon-menu" title="Main"></i> -->
          </li>
          <li
            v-for="(item, index) in baseMenuItems"
            :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }"  class="nav--link w-100 ">
              <i :class="item.icon"></i>
              <span>{{ item.text }}</span>
            </router-link>
          </li>

          <li
            class="nav--item py-0"
            v-show="(userType == userTypes.admin || userType == userTypes.editor)  && user.id != '5986'"
          >
            <router-link :to="{ name: 'NewEmail' }" class="nav--link w-100" >
                <i class="icon-paperplane"></i>
                <span class="text-left">Bulk Messages</span>
            </router-link>
          </li>

          <li
            class="nav--item py-0"
            v-show="(userType == userTypes.admin) "
          >
            <router-link :to="{ name: 'SystemPayments' }" class="nav--link w-100" >
                <i class="icon-stack-check"></i>
                <span class="text-left">System Payments</span>
            </router-link>
          </li>
          
          <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs color-faded line-height-xs">Orders</div>
            <!-- <i class="icon-menu" title="Main"></i> -->
          </li>

          <li
          class="nav--item">
              <router-link
              :to="{ name: 'Bids' }"
              style="position:relative!important;  width:100%!important;"
                          
              class="nav--link  ">
                  <i class="icon-add-to-list"></i>
                  <span class="nav-link-name">Bids</span>
                  <div
                  style="
                    width: 90px;
                    position: absolute!important;
                    float: right;
                    right: 0;
                    top: 5px;
                    " 
                    class="">
                    <p   
                    style=" 
                    width: 24px;
                    text-align: right;
                    float: right;
                    "               
                      class="badge bg-info d-flex align-items-center justify-content-center mt-2  badge-pill mr-3 "
                      >{{ bidsCount || 0}}</p>
                  </div>
              
               
              
              </router-link>
              
          </li>

          <li
          v-for="(item, index) in sidebarMenu"
          :key="index + 'om'"
          class="nav--item">
              <router-link
              @click="getSidebarMenu"
              :to="{ name: 'OrderListing', params: { status: item.value } }"
              style="position:relative!important; width:100%!important; "
                          
              class="nav--link  ">
                  
                  <i v-if ="item.icon" :class="item.icon"></i>
                  <span class="nav-link-name">{{ item.name }}</span>
                  <div
                  style="
                    width: 90px;
                    position: absolute!important;
                    float: right;
                    right: 0;
                    top: 5px;
                    " 
                    class="">
                    <p   
                    style=" 
                    width: 24px;
                    text-align: right;
                    float: right;
                    "               
                      class="badge d-flex align-items-center justify-content-center mt-2  badge-pill mr-3 "
                      :class="`bg-${item.color || 'dark'}`"
                      >{{ item.no || 0 }}</p>
                  </div>
              
               
              
              </router-link>
              
          </li>

          <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs color-faded line-height-xs">Menu</div>
            <!-- <i class="icon-menu" title="Main"></i> -->
          </li>

          <span
              v-show="userType == userTypes.admin && user.id != '5986'"
            >
          <li
          v-for="(item, index) in adminMenu"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <i :class="item.icon"></i>
              <span>{{ item.text }}</span>
            </router-link>
          </li>
          </span>

          <span
            v-show="userType && userType == userTypes.client"
            >
          <li
          v-for="(item, index) in clientMenu"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <i :class="item.icon"></i>
              <span>{{ item.text }}</span>
            </router-link>
          </li>
          </span>

          <span
          v-show="userType && userType == userTypes.editor"
            >
          <li
          v-for="(item, index) in editorMenu"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <i :class="item.icon"></i>
              <span>{{ item.text }}</span>
            </router-link>
          </li>
          </span>

          <span
          v-show="userType && userType == userTypes.writer"
            >
          <li
          v-for="(item, index) in writerMenu"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <i :class="item.icon"></i>
              <span>{{ item.text }}</span>
            </router-link>
          </li>
          </span>
          <span
          v-show="userType == userTypes.admin || userType == userTypes.client"
            >
          <li
          v-for="(item, index) in WriterLevels"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <i :class="item.icon"></i>
              <span>{{ item.text }}</span>
            </router-link>
          </li>
          </span>
          
                
        </ul>
      </div>
      <!-- /main navigation -->
    </div>
    <!-- /sidebar content -->
  </div>
  <!-- /main sidebar -->
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import {
  actionMenuItems,
  baseMenuItems,
  writerMenu,
  clientMenu,
  editorMenu,
  adminMenu,
  setupsMenu,
  WriterLevels
} from "./sidebar.model";

export default {
  name: "Sidebar",

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("sidebar", ["showSidebar", "sidebarMenu", "bidsCount"]),
    ...mapState("setups", ["site_settings"]),

  },
  data() {
    return {
      actionMenuItems: actionMenuItems,
      baseMenuItems: baseMenuItems,
      adminMenu: adminMenu,
      clientMenu: clientMenu,
      writerMenu: writerMenu,
      editorMenu: editorMenu,
      setupsMenu: setupsMenu,
      WriterLevels: WriterLevels, 
      toggle_setups: false,
    };
  },
 
  mounted() {
    this.getSidebarMenu();
    this.getSiteSettings()

  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("sidebar", ["getSidebarMenu"]),
    ...mapMutations("sidebar", ["_toggleSidebarMobile"]),
    ...mapActions("setups", ["getSiteSettings"]),

  },
};
</script>


<style lang="scss" scoped>
.sidebar-xs {
  width: 100% !important;
}
.nav-item {
  max-height: 40px !important;
  min-height: 40px !important;
  height: 40px !important;
}
.nav-link {
  padding: 8px 10px !important;
}
.sidebar {
  .nav-link {
    padding: 0.5rem 0.8rem;
  }
}
.modal-backdrop {
  z-index: 1 !important;
}

// .sidebar-xs .sidebar-main .nav-sidebar > .nav-item > .nav-link {
//   padding-left: 0;
//   padding-right: 0;
//   max-height: 40px !important;
// }

.is-conditional-menu * {
  transition: 0.3s all ease-in-out;
}

.nav--item > .nav--link.active {
  border-radius: 0px 20px 20px 0px;
  margin-right: 4px;
  background: rgba(184, 193, 198, 0.49) !important;
  transition: 0.3s ease-in-out;
}
.nav-link.active.just-active {
  max-height: 34px !important;
}
.sidebar-xs {
  .nav-item-header {
    display: none;
  }
}

.nav-sidebar .nav-link i {
  margin-right: 1.25rem;
  margin-top: 0rem !important;
  margin-bottom: 0.12502rem;
  top: 0;
}

.sidebar-xs .sidebar-main {
  // width: 2.8rem;
  width: 16.875rem;

  a.nav-link.bg-primary.py-1.rounded {
    border-radius: 0px 8px 8px 0px !important;
    max-height: 20px;
    margin-right: 5px;
  }
}
// .sidebar-xs .sidebar-main:hover {
//   width: 16.875rem;
//   -webkit-animation: slideInOut 0.2s;
//   -moz-animation: slideInOut 0.2s;
//   -o-animation: slideInOut 0.2s;
//   animation: slideInOut 0.2s;
// }

@keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}

@-o-keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}
@-moz-keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}
@-webkit-keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}

.nav-link.bg-primary {
  border-radius: 0px 8px 8px 0px;
}

.nav.nav-group-sub.d-block .nav-item .nav-link {
  padding-left: 22px !important;
}
.nav-item-submenu.nav-item-open a {
  border-radius: 0 8px 8px 0px !important;
}
</style>