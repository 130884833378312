// /plugins/vuetify.js
// import '@fortawesome/fontawesome-free/css/all.css'
import colors from 'vuetify/lib/util/colors'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'// import '@mdi/font/css/materialdesignicons.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'
import 'vuetify/lib'

Vue.use(Vuetify)

import 'vuetify/dist/vuetify.min.css'

const opts = {
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#2196f3',
                info: '#00bcd4',
                // primary: "#9652ff",
                // success: "#3cd1c2",
                // info: "#ffaa2c",
                // error: "#f83e70",

                secondary: '#673ab7',
                accent: '#252b36',
                warning: '#ff9800',
            },
            dark: {
                primary: '#2196f3',
                info: '#00bcd4',
                success: '#25b372',
                error: '#ff5722',
                // primary: colors.blue.lighten3,
                background: colors.indigo.base
            }
        },

        icons: {
            iconfont: 'md', // variations => 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        },

        options: {
            minifyTheme: function (css) {
                return process.env.NODE_ENV === 'production'
                    ? css.replace(/[\r\n|\r|\n]/g, '')
                    : css
            },
        },
    }
}

export default new Vuetify(opts)