import http from '../modules/http.index'
import { Form } from "vform"

const state = () => ({
    writer: {},
    writers: [],
    writerOrders: [],
    loading: false,
    headers: [
        {
            text: "Id",
            align: "start",
            filterable: false,
            value: "id",
        },
        { text: "Name", value: "names" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Country", value: "country" },
        { text: "Editor", value: "editor" },
        { text: "Level", value: "level" },
        { text: "Status", value: "status" },
        { text: "Rating", value: "rating" },
        { text: "Action", value: 'actions', sortable: false, },
    ],
    walletEntriesTableHeaders: [
        
    ],
    writerPrefsForm: new Form({
        writer_id: "",
        level_id: "",
        editor_id: "",
        status: false
    }),
    bulkActionForm: new Form({
        writer_ids: [],
        status: ""
    }),
    updateWriterPrefsUrl: `${process.env.VUE_APP_API_BASE_URL}/Writers/WriterEditor`,

})


const getters = {
    profile: (state) => state.writer.profile,
    orders: (state) => state.writer.orders,
    wallet_entires: (state) => state.writer.wallet_entires,
    wallet_balance: (state) => state.writer.wallet_balance
}
const mutations = {
    setWriter: (state, payload) => state.writer = payload,
    setWriters: (state, payload) => state.writers = payload,
    setWriterOrders: (state, payload) => state.writerOrders = payload
}
const actions = {
    async getWriter(ctx, id) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Users/Profile/${id}`)
                .then((res) => {
                    console.log('client', res);
                    ctx.commit("setWriter", res.data.Data)
                    ctx.state.loading = false
                    resolve(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },
    async getWriters(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Users/Writers/`)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setWriters", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async getAllWriters(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Writers/Writers/`)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setWriters", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async getActiveWriters(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Payments/Writers/`)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setWriters", res.data.Data)
                        ctx.state.loading = false
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    

    async getWriterOrders(ctx, payload) {
        return new Promise((resolve, reject) => {
            // ctx.state.loading = true
            http
                .post(`/Users/WriterOrders/`, {
                    writer_id: payload
                }) // payload = order status e.g pending, inprogress
                .then((res) => {
                    if (res.status === 200) {
                    ctx.commit("setWriterOrders", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
            // .finally(() => ctx.state.loading = false)
        })
    },

    async updateWriterPrefs(ctx, payload) {
        return new Promise((resolve, reject) => {
            // ctx.state.loading = true
            payload
                .put(ctx.state.updateWriterPrefsUrl) // payload = order status e.g pending, inprogress
                .then((res) => {
                    if (res.status === 200) {
                        ctx.dispatch("getAllWriters")
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
            // .finally(() => ctx.state.loading = false)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}