import Form from 'vform'
import http from '../modules/http.index'

const state = () => ({
    payment_vars: '',
    writer_id: '',
    paymentForm: new Form({
        type: '',
        amount: '',
        reference: '',
        call_back: '',
    }),





    discountsForm: new Form({
        percentage: "",
        code: "",
        on_orders_above: "",
        private: true,
        recursive: false,
        site_id: ""
    }),
    discounts: [],
    discountHeaders: [
        { text: "id", value: "id" },
        { text: "Code", value: "code" },
        { text: "Percentage", value: "percentage" },
        { text: "On orders above", value: "on_orders_above" },
        { text: "Recursive", value: "recursive" },
        { text: "Private", value: "private" },
        { text: "Order Based", value: "order_based" },
        // { text: "Site", value: "site" },
        { text: "Date Created", value: "create_stamp" },
        { text: "Action", value: "actions" },
    ],




    finesForm: new Form({
        id: "",
        writer_id: "",
        order_id: "-1",
        amount: "",
        description: ""
    }),
    fines: [],
    finesTotal: "",
    finesFilters: {},
    finesHeaders: [
        { text: "ID", value: "id" },
        { text: "Order", value: "order_id" },
        { text: "Date", value: "date_approved" },
        { text: "Description", value: "description" },
        { text: "Amount", value: 'amount' },
        { text: "Writer", value: "writer" },
        { text: "Action", value: "actions" },
    ],

    bonusForm: new Form({
        id: "",
        writer_id: "",
        order_id: "-1",
        amount: "",
        description: ""
    }),
    bonus: [],
    bonusTotal: "",
    bonusFilters: {},
    bonusHeaders: [
        { text: "ID", value: "id" },
        { text: "Order", value: "order_id" },
        { text: "Date", value: "date_approved" },
        { text: "Description", value: "description" },
        { text: "Amount", value: 'amount' },
        { text: "Writer", value: "writer" },
        { text: "Action", value: "actions" },
    ],

    loading: true,
    paymentUrl: `${process.env.VUE_APP_API_BASE_URL}/Checkout/GetUrl/`,
    wallet_entries: [],
    tips: [],
    pendingTotals: [],
    pendingPayments: [],
    paidPayments: [],

    pendingPaymentsHeaders: [
        { text: "#ID", value: 'id' },
        { text: "Order ID", value: 'order_id' },
        { text: "Pages", value: 'no_pages' },
        { text: "Amount per page", value: 'amount_per_page' },
        { text: "Total Amount", value: 'amount' },
        { text: "Writer", value: 'writer_names' },
        { text: "Date Created", value: "date_created" },
        // { text: "Date Approved", value: "date_approved" },
        { text: "Description", value: "description" },
        { text: "Order Status", value: "status" },
    ],

    paidPaymentsHeaders: [
        { text: "#ID", value: 'id' },
        { text: "Order ID", value: 'order_id' },
        { text: "Pages", value: 'no_pages' },
        { text: "Amount per page", value: 'amount_per_page' },
        { text: "Total Amount", value: 'amount' },
        { text: "Writer", value: 'writer_names' },
        { text: "Date Created", value: "date_created" },
        // { text: "Date Approved", value: "date_approved" },
        { text: "Description", value: "description" },
        { text: "Order Status", value: "status" },
    ],

    systemPaymentsHeaders: [
        { text: "#ID", value: 'id' },
        { text: "Gateway", value: 'gateway' },
        { text: "Url", value: 'url' },
        { text: "Amount", value: 'amount' },
        { text: "System Reference", value: 'system_reference' },
        { text: "Gateway Reference", value: 'gateway_reference' },
        { text: "Date Created", value: "create_stamp" },
    ],


    pendingPaymentWriters: [
        { text: "#ID", value: 'id' },
        { text: "Name", value: 'names' },
        { text: "Balance", value: 'balance' },
        { text: "Eligible for Payout", value: 'status' },
        { text: "Action", value: 'action' },

    ],
    balanceTotals: [],
    balancePayments: [],
    balancePaymentsHeaders: [
        { text: "Date Approved", value: "date_approved" },
        { text: "Description", value: "description" },
        { text: "Amount", value: 'amount' },
    ],
    wallet_balance: 0,
    walletEntriesUrl: `/Payments/WalletEntries/`,
    walletBalanceUrl: `/Users/WalletBalance/`,
    tipsUrl: `/Users/WalletBalance/`,
    SystemPayments:[],
    SystemPaymentsSummary:[]


})

const getters = {}
const mutations = {
    setPaymentVars: (state, payload) => state.payment_vars = payload,
    setWalletEntries: (state, payload) => state.wallet_entries = payload,
    setWalletBalance: (state, payload) => state.wallet_balance = payload,
    setTips: (state, payload) => state.tips = payload,
    setPaymentUrl: (state, payload) => state.paymentUrl = payload,
    setDiscounts: (state, payload) => state.discounts = payload,
    setSystemPayments: (state, payload) => state.SystemPayments = payload.payments,
    setSystemPaymentsSummary: (state, payload) => state.SystemPaymentsSummary = payload.summary,
    setPendingPayments: (state, payload) => {
        state.pendingPayments = payload.payments
        state.pendingTotals = payload.total
    },
    setPaidPayments: (state, payload) => state.paidPayments = payload.payments,
    setBalancePayments: (state, payload) => state.balanceTotals = payload,
    setFines: (state, payload) => {
        state.fines = payload.fines
        state.finesTotal = payload.total
        state.finesFilters = payload.filters
    },
    setBonus: (state, payload) => {
        state.bonus = payload.bonus
        state.bonusTotal = payload.total
        state.bonusFilters = payload.filters
    },
    setWriterId: (state, payload) => state.writer_id = payload
}
const actions = {
///get system payments
    async _getSystemPayments(ctx,) {

        return new Promise((resolve, reject) => {
            // ctx.commit("setWriterId", payload)
            ctx.state.loading = true
            http
                .get(`system/payments`)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                        ctx.commit("setSystemPayments", res.data.Data)
                        ctx.commit("setSystemPaymentsSummary", res.data.Data)

                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async _getPaymentUrl(ctx, payload = "") {
        return new Promise((resolve, reject) => {
            payload
                .post(ctx.state.paymentUrl)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.Data) {
                            ctx.commit("setPaymentVars", res.data.Data)
                        }
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => {
                    ctx.commit("setPaymentUrl", `${process.env.VUE_APP_API_BASE_URL}/Checkout/GetUrl/`)
                })
        })
    },

    async _getPendingPayments(ctx, payload = "") {

        return new Promise((resolve, reject) => {
            ctx.commit("setWriterId", payload)
            ctx.state.loading = true
            http
                .get(`/Payments/Pending/${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                        ctx.commit("setPendingPayments", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async _getPaidPayments(ctx, payload = "") {

        return new Promise((resolve, reject) => {
            ctx.commit("setWriterId", payload)
            ctx.state.loading = true
            http
                .get(`/Payments/PaidOrders/${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                        ctx.commit("setPaidPayments", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async _markAsPaid(ctx, payload) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .put(`/Payments/paid/`, payload)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.dispatch("_getPendingPayments", ctx.state.writer_id)
                        ctx.dispatch("_getPaidPayments", ctx.state.writer_id)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async _markAsUnpaid(ctx, payload) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .put(`/Payments/UnPaid/`, payload)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.dispatch("_getPendingPayments", ctx.state.writer_id)
                        ctx.dispatch("_getPaidPayments", ctx.state.writer_id)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async _getBalancePayments(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Payments/Balances`)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                        ctx.commit("setBalancePayments", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async _getFines(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Payments/Fines`)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("res.data.Data", res.data.Data);
                        ctx.commit("setFines", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },


    async _getBonus(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Payments/Bonus`)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("res.data.Data", res.data.Data);
                        ctx.commit("setBonus", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },



    async _getWalletBalance(ctx) {
        return new Promise((resolve, reject) => {
            http
                .get(ctx.state.walletBalanceUrl)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                        ctx.commit("setWalletBalance", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
        })
    },

    async _getWalletEntries(ctx, payload) { //payload = client_id
        ctx.state.loading = true
        return new Promise((resolve, reject) => {
            http
                .get(`/Payments/WalletEntries/${payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("wallet res", res);
                        resolve(res)
                        ctx.commit("setWalletEntries", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async _getTipEntries(ctx) {
        ctx.state.loading = true
        return new Promise((resolve, reject) => {
            http
                .get('/Users/Tips/')
                .then((res) => {
                    if (res.status === 200) {
                        console.log("tip res", res);
                        resolve(res)
                        ctx.commit("setTips", res.data.Data)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)

        })
    },


    async _getDiscounts(ctx) {
        ctx.state.loading = true
        return new Promise((resolve, reject) => {
            http
                .get(`/Discounts/Discounts/${ctx.rootState.site_id}`)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("discounts", res);
                        ctx.commit("setDiscounts", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },



}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}