import http from "../modules/http.index"
import { Form } from "vform"
const state = () => ({
    loading: false,
    writerLevels: [],
    writerLevelsForm: new Form({
        id: '',
        level: '',
        orders_to_get_here: 0,
        max_bids: 0,
        max_claims: 0,
        max_orders: 0,
        amount_per_page: 0
    }),
    headers: [
        { 
            text: "Id",
            align: "start",
            filterable: false,
            value: "id",
        },
        { text: "Level", value: "level" },
        { text: "Orders to get here", value: "orders_to_get_here" },
        { text: "Max Bids", value: "max_bids" },
        { text: "Max Claims", value: "max_claims" },
        { text: "Max Orders", value: "max_orders" },
        { text: "Amount Per Page", value: "amount_per_page" },
        { text: "Action", value: 'actions', sortable: false, },

    ],
})

const getters = {}
const mutations = {
    setWriterLevels: (state, payload) => state.writerLevels = payload
}
const actions = {
    async getWriterLevels(ctx, payload) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(payload || "/Setups/WriterLevels") // pass payload as id to get a levels for a single client
                .then((res) => {
                    if (res.status === 200) {
                        if (payload) {
                            ctx.commit("setWriterLevels", res.data.Data.writer_levels)
                        } else {
                            ctx.commit("setWriterLevels", res.data.Data.writer_levels)

                        }
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}