import http from "../modules/http.index"
import { Form } from "vform"
const state = () => ({
    loading: false,
    sites: [],
    adminSystems: [],
    sitesForm: new Form({
        site_id: '',
        name: '',
        url: '',
        application_url: '',
        gateway_checkout_url: '',
        admin_system: '',
        email: '',
        site_key: '',
        secret_key: '',
        client_id: '',
        tawk_code: '',
        is_admin_system: false,
        backgroundColor: '#234343',
        textColor: '#f4f4f8'
    }),
    headers: [
        {
            text: "Id",
            align: "start",
            filterable: false,
            value: "id",
        },
        { text: "Setups", value: 'setups', sortable: false,},
        { text: "Name", value: "name" },
        { text: "Url", value: "url" },
        { text: "App Url", value: "application_url" },
        { text: "Checkout", value: "gateway_checkout_url" },
        { text: "Email", value: "email" },
        { text: "Admin System", value: "admin_system" },
        { text: "Captcha Site Key", value: "site_key" },
        { text: "Captcha Secret Key", value: "secret_key" },
        { text: "Tawk Code", value: "tawk_code" },
        { text: "Action", value: 'actions', sortable: false, },

    ],
})

const getters = {}
const mutations = {
    setSites: (state, payload) => state.sites = payload,
    setAdminSystems: (state, payload) => state.adminSystems = payload
}
const actions = {
    async getSites(ctx, payload) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(payload || "/Sites/Sites")
                .then((res) => {
                    if (res.status === 200) {
                        if (payload) {
                            ctx.commit("setSites", res.data.Data.sites)
                        } else {
                            ctx.commit("setSites", res.data.Data)

                        }
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async getGetAdminSystems(ctx, payload) {
        return new Promise((resolve, reject) => {
            http
                .get(payload || "/Sites/AdminSites")
                .then((res) => {
                    ctx.commit("setAdminSystems", res.data.Data.sites)
                    resolve(res)
                })
                .catch((err) => reject(err))
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}