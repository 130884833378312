import axios from 'axios';
import http from '../modules/http.index'

const state = () => ({
  showSidebar: false,
  showSidebarMobile: false,
  sidebarMenu: [],
  bidsCount: null
});
const getters = {
};
const mutations = {
  _toggleSidebar(state) {
    state.showSidebar = !state.showSidebar
  },
  _toggleSidebarMobile(state) {
    console.log("state", state.showSidebar);
    state.showSidebar = !state.showSidebar
    state.showSidebarMobile = !state.showSidebarMobile
    if (state.showSidebarMobile) {
      document.body.classList.add("sidebar-mobile-main")
    } else {
      document.body.classList.remove("sidebar-mobile-main")
    }
  },
  setSidebarMenu: (state, payload) => state.sidebarMenu = payload,
  setBidsCount: (state, payload) => state.bidsCount = payload.no_bids

};
const actions = {
  // toggle sidebar
  // expand sidebar to full screen on mobile
  // toggle main sidebar on mobile

  // sidebar menu
  async getSidebarMenu(ctx) {
    return new Promise((resolve, reject) => {
      let req1 = http.get('/Orders/OrderStats/')
      let req2 = http.get('/Bids/BidStats/')
      axios.all([req1, req2])
        .then(axios.spread((...responses) => {
          ctx.commit('setSidebarMenu', responses[0].data.Data)
          ctx.commit('setBidsCount', responses[1].data.Data)

          // const responseTwo = responses[1]
        }))
      http.get('/Orders/OrderStats/')
        .then((res) => {
          if (res.status === 200) {
            ctx.commit('setSidebarMenu', res.data.Data);
            resolve(res)
          } else reject(res)
        })
        .catch((err) => reject(err))
    })
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
