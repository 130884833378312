import http from '../modules/http.index'
import { Form } from "vform"
const state = () => ({
    orders: [],
    filters: {},
    title: '',
    bids: [],
    bidsUrl: `${process.env.VUE_APP_API_BASE_URL}/Bids/Bid`,
    bidsForm: new Form({
        order_id: "",
        writer_id: "",
    }),
    bidsTableAdmin: [
        {
            text: "Order Id",
            align: "start",
            value: "order_id",
        },
        {
            text: "Title",
            align: "start",
            value: "title",
        },
        {
            text: "Deadline",
            align: "start",
            value: "deadline",
        },
        {
            text: "Bids",
            align: "start",
            value: "bids",
        },
        
    ],
    bidsTableHeaders: [
        {
            text: "Id",
            align: "start",
            value: "id",
        },
        {
            text: "Order Id",
            align: "start",
            value: "order_id",
        },

        {
            text: "Order Topic",
            align: "start",
            value: "title",
        },
        {
            text: "Bidded By",
            align: "start",
            value: "names",
        },
        {
            text: "Amount",
            align: "start",
            value: "amount",
        },

        {
            text: "Bidded At",
            align: "start",
            value: "date_placed",
        },

        {
            text: "Action",
            align: "start",
            value: "action",
        },
    ],
    adminTableHeaders: [
        {
            text: "Id",
            align: "start",
            value: "id",
        },

        { text: "Topic", value: "title", align: 'start' },
        { text: "Writer Deadline", value: "writer_deadline", width: '124px' },
        { text: "Editor Deadline", value: "editor_deadline", width: '124px' },
        { text: "Client Deadline", value: "client_deadline", width: '124px' },
        { text: "Pages", value: "pages", align: 'start' },
        { text: "Order Price", value: "cost", align: 'start' },
        { text: "Writer Cost", value: "writer_cost", align: 'start' },
        { text: "System Client", value: "system_client_name", align: 'start' },
        { text: "Client", value: "client_name", align: 'start' },
        { text: "Editor", value: "editor_name", align: 'start' },
        { text: "Writer", value: "writer_name", align: 'start' },
        { text: "Site", value: "site", align: 'start' },
        { text: "Action", value: 'actions', sortable: false, align: 'start' },
    ],
    userOrdersTableHeaders: [
        {
            text: "#No",
            align: "start",
            value: "no",
        },
        {
            text: "Id",
            align: "start",
            value: "id",
        },

        { text: "Topic", value: "title", align: 'start' },
        { text: "Pages", value: "pages", align: 'start' },
        { text: "System Client", value: "system_client_name", align: 'start' },
        { text: "Client", value: "client_name", align: 'start' },
        { text: "Site", value: "site", align: 'start' },
        { text: "Order Price", value: "cost", align: 'start' },
        { text: "Writer Cost", value: "writer_cost", align: 'start' },
        { text: "Editor Cost", value: "editor_cost", align: 'start' },
        { text: "Deadline", value: "date_ordered", align: 'start' },
        { text: "Status", value: "status_desc", align: 'start' },
        { text: "Action", value: 'actions', sortable: false, align: 'start' },
    ],

    editorOrdersTableHeaders: [
        {
            text: "Id",
            align: "start",
            value: "id",
        },

        { text: "Topic", value: "title", align: 'start' },
        { text: "Pages", value: "pages", align: 'start' },
        { text: "Client", value: "client_name", align: 'start' },
        { text: "Status", value: "status_desc", align: 'start' },
        { text: 'Urgency', value: "urgency_text", align: 'start' },
        { text: "Action", value: 'actions', sortable: false, align: 'start' },
    ],

    systemClientTableHeaders: [
        {
            text: "Id",
            align: "start",

            value: "id",
        },

        { text: "Topic", value: "title", align: 'start' },
        { text: "Writer Deadline", value: "urgency_text", },
        { text: "Pages", value: "pages", align: 'start' },
        { text: "Writer", value: "writer_name", align: 'start' },
        { text: "Client", value: "client_name", align: 'start' },
        { text: "Site", value: "site", align: 'start' },
        { text: "Order Price", value: "cost", align: 'start' },
        { text: "Writer Cost", value: "writer_cost", align: 'start' },
        { text: "Action", value: 'actions', sortable: false, align: 'start' },
    ],

    writerTableHeaders: [
        {
            text: "Id",
            align: "start",

            value: "id",
        },
        { text: "Topic", value: "title", align: 'start' },
        { text: "Deadline", value: "writer_deadline", width: '124px' },
        { text: "Pages", value: "pages", align: 'start' },
        { text: "Amount", value: "writer_cost", align: 'start' },
        { text: "Action", value: 'actions', sortable: false, align: 'start' },
    ],
    subadminTableHeaders: [
        {
            text: "Id",
            align: "start",

            value: "id",
        },
        { text: "Topic", value: "title", align: 'start' },
        { text: "Pages", value: "pages", align: 'start' },
        { text: "Action", value: 'actions', sortable: false, align: 'start' },
    ],

    editorTableHeaders: [
        {
            text: "Id",
            align: "start",

            value: "id",
        },
        { text: "Topic", value: "title", align: 'start' },
        { text: "Writer Deadline", value: "writer_deadline", width: '124px' },
        { text: "Deadline", value: "editor_deadline", width: '124px' },
        { text: "Pages", value: "pages", align: 'start' },
        { text: "Client", value: "client_name", align: 'start' },
        { text: "Writer", value: "writer_name", align: 'start' },
        { text: "Writer Cost", value: "writer_cost", align: 'start' },
        { text: "Action", value: 'actions', sortable: false, align: 'start' },
    ],
})
const getters = {}
const mutations = {
    setOrders: (state, payload) => {
        state.orders = payload.Data.orders // Data = res.data.Data
        state.filters = payload.Data.filters
        state.title = payload.Message // title
    },
    setBids: (state, payload) => state.bids = payload
}
const actions = {
    async _getOrders(ctx, payload) {
        return new Promise((resolve, reject) => {
            http
                .get(`/Orders/Orders/${payload}`) // payload = order status e.g pending, inprogress
                .then((res) => {

                    if (res.status === 200) {
                        ctx.commit("setOrders", res.data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
        })
    },

    async getBids(ctx) {
        return new Promise((resolve, reject) => {
            http
                .get(`/Bids/Bids`) // payload = order status e.g pending, inprogress
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setBids", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
        })
    },

    async bidOrder(ctx, payload) {
        return new Promise((resolve, reject) => {
            // payload is bidsForm
            payload.post(ctx.state.bidsUrl)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },

    async _navigate(ctx, payload) {
        return new Promise((resolve, reject) => {
            http
                .get(payload) // payload = order status e.g pending, inprogress
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setOrders", res.data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
        })
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}