import Axios from "axios";
import objectToFormData from "../assets/js/objectToFormData"

let token = localStorage.getItem("token");
let baseURL = `${process.env.VUE_APP_API_BASE_URL}`
function fullEndpoint(endpoint = "") {
  return baseURL + endpoint
}

Axios.defaults.headers.common = {
  Accept: "application/json",
  "X-Requested-With": "XMLHttpRequest",
  Authorization: token,
};


const http = Axios.create({
  // eslint-disable-next-line no-undef
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token,
  },
});


export default class Request {
  constructor() {
    
  }

  

  apiGet(endpoint, data = null) {
    return new Promise((resolve, reject) => {
      try {
        let res = http.get(endpoint, data);
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  }

  apiPost(endpoint, data) {
    return new Promise((resolve, reject) => {
      try {
        let res = http.post(endpoint, data);
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  }

  apiPut(endpoint, data = null) {
    return new Promise((resolve, reject) => {
      try {
        let res = http.put(endpoint, data);
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  }

  apiDelete(endpoint) {
    return new Promise((resolve, reject) => {
      try {
        let res = http.delete(endpoint);
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  }

  apiFormPost(endpoint, formData) {
    return new Promise((resolve, reject) => {
      try {
        let res = formData.post(fullEndpoint(endpoint));
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  }

  apiFormPostMultipart(endpoint, formData) {
    return new Promise((resolve, reject) => {
      try {
        let res = formData.post(fullEndpoint(endpoint), {
          transformRequest: [function (data) {
            return objectToFormData(data)
          }]
        });
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  }

  apiFormPut(endpoint, formData) {
    return new Promise((resolve, reject) => {
      try {
        let res = formData.put(fullEndpoint(endpoint));
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  }

  
}