import Form from 'vform'
import http from '../modules/http.index'

const state = () => ({
    loading: false,
    mailingForm: new Form({
        subject: "",
        message: "",
        site_id: "",
    }),
    mails: []
})


const getters = {

}

const mutations = {
    setMails: (state, payload) => state.mails = payload,
    setLoading: (state, payload) => state.loading = payload
}

const actions = {
    async sendMail(ctx, payload) { // payload is post url
        console.log("payload", payload);
        return new Promise((resolve, reject) => {
            ctx.state.mailingForm.post(`${process.env.VUE_APP_API_BASE_URL + payload}`)
                .then((res) => {
                    if (res.status === 200) {
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
        })
    },

    async getMails(ctx) {
        return new Promise((resolve, reject) => {
            ctx.commit('setLoading', true)
            http.get('/Notifications/List')
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.Data) {
                            ctx.commit("setMails", res.data.Data)
                        }
                        resolve(res)
                    } else reject(res)
                })
                .catch(err => reject(err))
                .finally(() => ctx.commit('setLoading', false))
        })
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}